import React, { useEffect } from "react";
import Card from "../components/NewCard"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
// import CarrousselPro from "../components/CarrousselPro"
import { SEO } from "../components/seo"
import SocialLinks from "../components/socialPro"
import bbLogo from "../images/manualdomaker.svg"
import { StaticImage } from "gatsby-plugin-image"

// Miniaturas: 363x217

export const query = graphql`
query myQuery($skip: Int!, $limit: Int!) {
    allStrapiArticle(sort: {publishedAt: DESC}, limit: $limit, skip: $skip) {
      nodes {
        id
        title
        slug
        excerpt
        thumbnail {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 362
                height: 217
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: WEBP
                webpOptions: { quality: 85 }
                quality: 85
              )
            }
          }
        }
        category {
          slug
        }
        body {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

const ArticleList = ({ data, pageContext, index }) => {
  const articles = data.allStrapiArticle.nodes
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : "/page/" + (currentPage - 1).toString()
  const nextPage = currentPage < numPages ? "/page/"+ (currentPage + 1).toString() : "/page/" + currentPage 

  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render


  return (
    <>
        <main>
            <Layout>

                <section>
                      <div className="relative">

                        <div className="z-10 backdrop-blur-sm overflow-hidden bg-no-repeat bg-blend-multiply bg-center  bg-fixed rounded-2xl ring-2 ring-orange-500 ring-opacity-100 bg-black bg-opacity-60">   
                          <div className="h-60 bg-gradient-to-b from-black to-transparent to-50%   w-full">
                          
                            <div className="relative h-40 top-12 bg-black opacity-70 rounded-xl w-80 ring-opacity-30 ring-1 ring-neonGreen"  style={{ backgroundImage: `url(${bbLogo})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
                            <p className="absolute left-2 -top-5 font-sans font-medium z-20 text-[44px] opacity-90">Manual</p>
                            <p className="absolute left-36 top-12 font-sans font-medium z-20 text-[24px]">do</p>
                            <p className="absolute right-2 top-20 font-sans font-medium z-20 text-[44px] opacity-90">Maker</p>
                            <p className="absolute text-orange-500 left-1/2 translate-x-1/2 top-7 font-sans font-medium z-20 text-[96px] opacity-90 mt-7 ml-16 ">.</p>
                            <p className="absolute text-orange-500 left-2/3 ml-12 mt-6 top-28 font-sans font-medium z-20 text-[28px] opacity-90">com</p>
          
                              {/* <CarrousselPro/>  */}
                            </div> 
                          </div> 
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 cursor-pointer">
                            
                                {articles.map(post => (
                                    <Card
                                    key={post.id || index}
                                    linkTo={post.slug}
                                    image={post.thumbnail.localFile.childImageSharp.gatsbyImageData}
                                    categoryName={post.category.slug} 
                                    imgAlt="Arduino Raspberry Espressif LabMaker IA web"
                                    title={post.title}
                                    text={post.excerpt}
                                    />
                                ))}

                            </div>
                            <div className="flex justify-between bg-gradient-to-b from-transparent to-black rounded-t-full h-16">
                            {!isFirst && (
                                <Link to={prevPage} rel="prev">
                                    ← Página anterior
                                </Link>
                            )}
                            {!isLast && (
                                <Link to={nextPage} rel="next">
                                     Próxima página →
                                </Link>
                            )}
                            </div>
                            {/* <div className="absolute top-0 -right-10"><SocialLinks /></div> */}
                        </div>{/*  div que comporta toda a página*/}
                        <div className="w-[224px] absolute top-0 -right-120 hidden lg:block"><SocialLinks /></div>
                        <Link to="/toolbox" aria-label="Toolbox" className="hidden lg:block"><div className="hover:rotate-6 hover:scale-150 ease-in duration-150 w-16 h-16 absolute top-4 right-4 hidden lg:block"><StaticImage src="../images/toolbox.svg" alt="toolbox" placeholder="blurred"/></div></Link>
                      </div>
                </section>
            </Layout>
        </main>

    </>
)

}


export default ArticleList

export const Head = () => (
  <SEO pageType={"CollectionPage"} thumbnail={"/manualdomaker-card.png"}/>
)